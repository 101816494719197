import React from 'react';
import './ReactVideo.css';

class ReactVideo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {currentVideo: ''};
        this.videoList = [
            'butterfly',
            'atoms',
            'robin',
            'disco-ball',
            'neon',
            'paint',
            'people',
            'turntable'
        ];
      }
    componentDidMount() {
        
        this.setState({
            currentVideo: this.videoList[Math.floor(Math.random() * (this.videoList.length))] + '.mp4'
        });
        this.timerID = setInterval(
          () => this.tick(),
          10000
        );
      }
      componentWillUnmount() {
        clearInterval(this.timerID);
      }

      tick() {
        //const v = Math.floor(Math.random() * (this.videoList.length));
        //console.log(v);
        this.setState({
            currentVideo: this.videoList[Math.floor(Math.random() * (this.videoList.length))] + '.mp4'
        });
        console.log(this.state.currentVideo);
      }


    render(){
        
        return (
            <div className="video-container">
            <video autoPlay muted loop id="myVideo" key={this.state.currentVideo}>
              <source src={"/video/" + this.state.currentVideo} type="video/mp4" />
            </video>
            </div>
        );
    }
  }
  
  export default ReactVideo;