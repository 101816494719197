import React from 'react';
import ReactVideo from './components/ReactVideo';
import ThirdHeader from './components/ThirdHeader';
import './App.css';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inProduction: true
    }
    
  }
 render(){
  return (
    <div className="App">
      <ReactVideo inProduction={this.state.inProduction} />
      <ThirdHeader inProduction={this.state.inProduction} />
    </div>
  )
 }
}

export default App;
