import React from 'react';
import './ThirdHeader.css';

class ThirdHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isProduction: this.props.isProduction
    }
    
  }
 render(){
  return (
    <header className="content">
        <div className="logo">
        <img src="/images/3rd-web-logo2-749x314.png" alt="3rdWeb" />
        </div>
        <section className="coming-soon">
        <div className="coming-soon-text">
         <p>... coming soon ...</p>
         </div>
      </section>
      </header>
  )
 }
}

export default ThirdHeader;
